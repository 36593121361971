<template>
  <div class="p-fluid">
    <div class="p-col-12 p-lg-12">
      <div class="card card-w-title">
        <div class="manager-title">{{ title }}</div>
<!--        <div class="note">(*) Nội dung không được bỏ trống</div>-->
        <formEdit
          :key="componentKey"
          :mode="mode"
          @back="backToList"
          @save="saveData"
          :model="models"/>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import Config from '../../../config/index'
import DataServices from '@/core/DataServices'
import formEdit from './add'
export default {
  components: {
    formEdit
  },
  data() {
    return {
      validationErrors: [],
      title: "Tạo nhóm quyền",
      mode: 'add',
      models: {},
      images: [],
      oldImages: [],
      componentKey: 0,
      page_transaction:null
    }
  },
  async mounted () {
    console.log("this.$route.name:",this.$route.query.page_transaction);
    this.mode = this.$route.name === 'editGroup' ? 'edit' : this.mode;
    this.title = this.mode === 'edit' ? 'Chỉnh sửa nhóm quyền' : this.title;
    //if (this.$route.params.id !== undefined) await this.getData(this.$route.params.id);
    if (!this.$commonFuction.isEmpty(this.$route.query)){
      if (!this.$commonFuction.isEmpty(this.$route.query.page_transaction)){
        this.page_transaction = this.$route.query.page_transaction;
      }
    }
    //check quyền
    var arr_role = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_GROUP);
    if (this.mode == 'edit'){
      if (arr_role.indexOf(this.$constants.ROLE.EDIT) < 0){
        this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
      }
    }else {
      if (arr_role.indexOf(this.$constants.ROLE.ADD) < 0){
        this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
      }
    }
  },
  methods: {
    backToList() {
      this.$router.push({ path: '/admin/list-group' });
    },
    convertPhone(numberPhone) {
      var phone = numberPhone
      if (numberPhone[0] === '0') {
        phone = phone.substring(1);
      }
      phone = phone.replace(' ', '')
      phone = '+84' + phone
      return phone
    },
    async saveData(params) {
      console.log("savesavesave")
      // console.log("===params===",params.list_controller[0]);
      if (params !== undefined && Object.entries(params).length > 0) {
        var uid = this.$store.getters.user
        var updated_at = new Date();
        var objects = {
          'name': params.name,
          'updated_by': uid,
          'updated_at': updated_at,
          'plain_name': this.$commonFuction.SkipVN(params.name),
        }
        var tmp_index = {};
        var dataInsertPermission = [];
        var dataInsertGroupRole = [];
        for (var i=0;i<params.list_controller.length;i++){
          if(params.list_controller[i].is_parent == 0){
            if(params.list_controller[i].roles){
              for (let j = 0; j < params.list_controller[i].roles.length; j++) {
                const e = params.list_controller[i].roles[j];
                if(e.check == true){
                  let itemGroupRole = {
                    role_id:e.id
                  };
                  let tmp_group_id = -1;
                  if (this.mode != 'add') { //nếu là chỉnh sửa
                    if (!this.$commonFuction.isEmpty(this.$route.params.id)){
                      itemGroupRole.group_id = parseInt(this.$route.params.id);
                      tmp_group_id = parseInt(this.$route.params.id);
                    }
                  }
                  dataInsertGroupRole.push(itemGroupRole);

                  console.log("dataInsertGroupRole",dataInsertGroupRole)
                  //thêm bảng phân quyền permission
                  if(e.list_table !== null){
                    for (let mmm = 0; mmm < e.list_table.length; mmm++) {
                      const element = e.list_table[mmm];
                      let check_exi = tmp_index[element.table] ? tmp_index[element.table] : 0;
                      if(check_exi > 0){
                        let indd = check_exi - 1;
                        if(element.select && element.select == 1){
                          dataInsertPermission[indd].is_select = true;
                        }
                        if(element.insert && element.insert == 1){
                          dataInsertPermission[indd].is_add = true;
                        }
                        if(element.update && element.update == 1){
                          dataInsertPermission[indd].is_update = true;
                        }
                        if(element.delete && element.delete == 1){
                          dataInsertPermission[indd].is_deleted = true;
                        }
                      }else{
                        
                        let dta = {
                          id_table : element.table,
                          is_select : false,
                          is_add : false,
                          is_update : false,
                          is_deleted : false
                        }
                        if(element.select && element.select == 1){
                          dta.is_select = true;
                        }
                        if(element.insert && element.insert == 1){
                          dta.is_add = true;
                        }
                        if(element.update && element.update == 1){
                          dta.is_update = 1;
                        }
                        if(element.delete && element.delete == 1){
                          dta.is_deleted = true;
                        }
                        if(tmp_group_id > -1){
                          dta.group_id = tmp_group_id;
                          console.log("group_id",dta.group_id)
                        }
                        let count_tmp = dataInsertPermission.length +1;
                        tmp_index[element.table] = count_tmp;
                        dataInsertPermission.push(dta);
                      }
                    }
                  }
                }
              }
            }
          }else{
            // console.log("params.list=",params.list_controller[i]);
            var check_tab_sub = false;//kiểm tra có tab con hay không để thêm tab cha
            if(params.list_controller[i].childs){
              var tmp = params.list_controller[i].childs;
              for (let k = 0; k < tmp.length; k++) {
                const el = tmp[k];
                if(el.roles){
                  for (let m = 0; m < el.roles.length; m++) {
                    var elm =  el.roles[m];
                    if(elm.check == true){
                      check_tab_sub = true;
                      let itemGroupRole = {
                        role_id:elm.id
                      };
                      let tmp_group_idm = -1;
                      if (this.mode != 'add') { //nếu là chỉnh sửa
                        if (!this.$commonFuction.isEmpty(this.$route.params.id)){
                          itemGroupRole.group_id = this.$route.params.id;
                          tmp_group_idm = this.$route.params.id;
                        }
                      }
                      dataInsertGroupRole.push(itemGroupRole);
                      if(elm.list_table !== null){
                        for (let mmmm = 0; mmmm < elm.list_table.length; mmmm++) {
                          const elementm = elm.list_table[mmmm];
                          let check_exim = tmp_index[elementm.table] ? tmp_index[elementm.table] : 0;
                          if(check_exim > 0){
                            let indd = check_exim - 1;
                            if(elementm.select && elementm.select == 1){
                              dataInsertPermission[indd].is_select = true;
                            }
                            if(elementm.insert && elementm.insert == 1){
                              dataInsertPermission[indd].is_add = true;
                            }
                            if(elementm.update && elementm.update == 1){
                              dataInsertPermission[indd].is_update = true;
                            }
                            if(elementm.delete && elementm.delete == 1){
                              dataInsertPermission[indd].is_deleted = true;
                            }
                          }else{
                            
                            let dta = {
                              id_table : elementm.table,
                              is_select : false,
                              is_add : false,
                              is_update : false,
                              is_deleted : false
                            }
                            if(elementm.select && elementm.select == 1){
                              dta.is_select = true;
                            }
                            if(elementm.insert && elementm.insert == 1){
                              dta.is_add = true;
                            }
                            if(elementm.update && elementm.update == 1){
                              dta.is_update = true;
                            }
                            if(elementm.delete && elementm.delete == 1){
                              dta.is_deleted = true;
                            }
                            if(tmp_group_idm > -1){
                              dta.group_id = tmp_group_idm;
                            }
                            let count_tmpm = dataInsertPermission.length +1;
                            tmp_index[elementm.table] = count_tmpm;
                            dataInsertPermission.push(dta);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            if(check_tab_sub == true){
              var tmp_role = params.list_controller[i].roles//lấy roles
              // console.log("==tmp_role=",tmp_role)
              for (let n = 0; n < tmp_role.length; n++) {
                if(tmp_role[n].type == 'view'){
                  let itemGroupRole = {
                    role_id:tmp_role[n].id
                  };
                  if (this.mode != 'add') { //nếu là chỉnh sửa
                    if (!this.$commonFuction.isEmpty(this.$route.params.id)){
                      itemGroupRole.group_id = this.$route.params.id;
                    }
                  }
                  dataInsertGroupRole.push(itemGroupRole);
                  break;
                }   
              }
            }
          }
          
        }
        // console.log("tmp_index",tmp_index)
        // console.log("===",dataInsertPermission)
        // var ttt = 1;
        // if(ttt == 1){
        //   return false;
        // }
        var that = this;
        console.log(params.list_controller)
        if (this.mode === 'add') {
          var dataInsert = this.$commonFuction.convertJsonObject(objects);
          console.log("dataInsert",dataInsert)
          dataInsert.group_roles = {data: this.$commonFuction.convertJsonObject(dataInsertGroupRole)};
          // dataInsert.permissions = {data: this.$commonFuction.convertJsonObject(dataInsertPermission)};
          await that.$apollo.mutate({
            //mutation: gql(queryUsers),
            mutation: DataServices.insertData('group'),
            variables: {
              objects: dataInsert
            }
          }).then( (res)  => {
            //var event_id = res.data.insert_events.returning[0].id;
            console.log('Thành công');
            console.log(res);//
            this.$commonFuction.is_loading(false);
            that.$toast.add({severity: 'success', summary: 'Thông báo', detail: 'Thêm thành công', life: 3000});
            this.$router.push({path: '/admin/list-group'});
          }).catch(async error => {
            console.log('error', error);
            that.$toast.add({
              severity: 'error',
              summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
              detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
              life: 3000
            });
            this.$commonFuction.is_loading(false);
          });
        } else  {
          var group_id = this.$route.params.id;
          var queryUpdate = `
            mutation update_users_multil($group_object: group_set_input,$group_role_objects: [group_role_insert_input!]!) {
              update_group(where: { id: {_eq: "${group_id}"}}, _set: $group_object) {
                affected_rows
                returning {
                  id
                }
              }
              delete_group_role(where: { group_id: {_eq: "${group_id}"}}) {
                returning {
                  id
                }
              }
              insert_group_role(
                objects: $group_role_objects
              ) {
                returning {
                  id
                }
              }

            }
          `;
        //   delete_permissions(where: { group_id: {_eq: "${group_id}"}}) {
        //     returning {
        //       id
        //     }
        //   }
        //   insert_permissions(
        //       objects: $permissions_objects
        // ) {
        //     returning {
        //       id
        //     }
        //   }
          let variables = {
            group_object: objects,
            group_role_objects: dataInsertGroupRole,
            // permissions_objects: dataInsertPermission
          };
          console.log("variables",variables)
          this.$apollo.mutate({
            mutation: gql(queryUpdate),
            variables: variables
          }).then(() => {
            that.$commonFuction.is_loading(false);
            var router_back = {path: '/admin/list-group'};
            if (!that.$commonFuction.isEmpty(router_back)){
              router_back.query = { page_transaction: that.page_transaction.toString() };
            }
            that.$router.push(router_back);
            that.$toast.add({ severity: 'success', summary: 'Thành công', detail: 'Lưu dữ liệu thành công', life: 3000 })
          }).catch(async error => {
            console.log('error', error);
            that.$toast.add({
              severity: 'error',
              summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
              detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
              life: 3000
            });
            that.$commonFuction.is_loading(false);
            // this.summitEvent = false;
          });

        }
      }
    },
    async saveListCategory(userID,list,type="add") {
      if(type == "add"){
        var list_add = []
        for (let i = 0; i < list.length; i++) {
          const el = list[i];
          list_add.push({uid :userID,category_id : el.id})
        }
        if(list_add.length > 0){
          var query_insert = DataServices.insertData("users_category")
          var variables_insert = {
            objects: list_add
          }
          await this.$apollo.mutate({
            mutation: query_insert,
            variables: variables_insert
          }).then(() => {
          })
        }
      }else if(type == "del"){
        let query = `mutation Mymutation($_in: [bigint!] = "", $_eq: uuid = "") {
          delete_users_category(where: {_and: {category_id: {_in: $_in}, uid: {_eq: $_eq}}}) {
            affected_rows
          }
        }`;
        var list_del = []
        for (let i = 0; i < list.length; i++) {
          const element = list[i];
          list_del.push(element.id)
        }
        if(list_del.length > 0){
          let variables_del = {
            _in : list_del,
            _eq : userID,
          }
          await this.$apollo.mutate({
            mutation: gql(query),
            variables: variables_del
          }).then(() => {
          })
        }
      }
    },
    async uploadImagesOld(userID) {
      var s3client = Config.s3Client('user')
      if (this.images && userID) {
        //xoá avatar
        if (this.oldImages.length > 0) await this.deleteAvatar(s3client);

        this.$store.commit('setLoading', true);
        var objects = null;
        for (let index = 0; index < this.images.length; index++) {
          const img = this.images[index];
          let file = img.source
          if (file !== '' && img.file !== '') {
            let fileName = img.name;
            let random = Math.random().toString().slice(2);
            fileName = `${random}_${fileName}`
            await s3client.uploadFile(file, fileName).then((data) => {
              objects = data.location;
            });
          }
        }
        // console.log(objects);
        if (objects !== null) {
          this.$apollo.mutate({
            mutation: DataServices.updateData('users'),
            variables: {
              'data_update': {
                'avatar': objects
              },
              'where_key': {
                'id': {'_eq': userID}
              }
            }
          }).then(() => {
            // console.log('UPDATE AVATAR THANH CONG')
          }).catch((error) => {
            console.error('ERROR: ', error)
          })
        }
      }
    },
    async deleteAvatar(s3client) {
      for (let i = 0; i < this.oldImages.length; i++) {
        let fileName = this.oldImages[i].name
        await s3client.deleteFile(fileName)
      }
    },
    /*async getData(id) {
      var where = {id: {'_eq': id}};
      var data = await this.$CoreService.getObjectData('group',where);
      console.log("datadatadatadata:",data)
      if (data){
        this.models = data;
      }

    },*/

  },
  apollo: {
    // categories: {
    //   query() {
    //     return DataServices.getList('category', {"fields" : "id name"});
    //   },
    //   update(data) {
    //     return data[Object.keys(data)];
    //   },
    //   fetchPolicy: "network-only"
    // },
    // users: {
    //   query() {
    //     return DataServices.getList('users', {"fields" : "id fullname phone"});
    //   },
    //   update(data) {
    //     return data[Object.keys(data)];
    //   },
    //   fetchPolicy: "network-only"
    // }
  }
}
</script>

<style lang="scss">
.p-inputnumber-buttons-stacked {
  .p-inputnumber-button-group {
    .p-button.p-button-icon-only { padding: 0 !important; }
  }
}
.note{ letter-spacing: 0px; color: #707070; margin-bottom: 24px; padding-top: 10px;}
.p-formgrid { margin: 0;
  .p-panel-header {color: #000000; font-size: 18px; font-weight: bold; background: #EEEEEE;}
  .p-card-field{ padding: 24px;
    .p-field{
      .p-col-fixed { width: 300px; display: inline-block !important; margin: 0;}
      .p-col-width{width:500px !important; border-radius: 5px;
        &.disabled{background: #EEEEEE; border: 0 none; padding: 8px 10px;}
        .file-select{margin: 0;
          input[type="file"]{display: none;}
          .select-button{display: inline-block; cursor: pointer; background: transparent; border-radius: 5px;  color: #000000; font-size: 13px; padding: 3px 16px;  border: 1px solid #B4B4B4;}
        }
        &.pac-target-input{ height: 80px;  word-break: break-all; overflow-wrap: break-word;}
      }
      .box-images{ margin-top: 10px; position: relative;
        .item-img{height: 150px;  margin-right: 8px; margin-bottom: 8px; display: inline-flex; position: relative; border: 0.5px solid #ccc;
          &.p-col-5{width: 18%;;}
          img{max-width: 100%; height: auto;margin: 0 auto; object-fit: contain;}
          .button-clear{width: 18px; height: 18px; background: #F5564E; float: right; cursor: pointer; position: absolute; right: 0px;
            img{vertical-align: top;}
          }
        }
      }
      .p-invalid{display: block;}
    }
  }
  .fixed-bottom-toolbar{background: transparent; border: 0 none; margin-top: 22px; border-top: 1px solid #B4B4B4; padding: 0; padding-top: 30px;
    .p-button{width: auto; margin-right: 8px;
      &:last-child{margin-right: 0;}
    }
  }
}
</style>