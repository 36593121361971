<template>
  <form id="frm_action">
  <div class="p-formgrid">
        <div class="p-card-field">
          <div class="p-col-8 p-offset-1">
            <div class="p-card-field">
              <label style="font-weight: bold;" class="p-col-fixed txt-right">Tên nhóm quyền  <span style="color: red">*</span></label>
              <div class="p-col">
                <Textarea :autoResize="true"  rows="1" maxlength="255" v-model="name"  id="name" type="text" class="p-col-width input_text" placeholder="Nhập " />
              </div>
            </div>
            <div class="p-field p-grid box-permission" v-for="(item_c, index_c) in list_controller" :key="index_c"   >
              <div class="p-col-4 box-left permission-left">
                <div class="box span-label">{{ item_c.name }}</div>
              </div>
              <div class="p-col-8 box-right permission-right">
                <!-- cho 1 cấp -->
                <div v-if="item_c.is_parent == 0" class="row custom-lable div-checkbox">
                  <label v-for="(roles_for, index_roles_for) in item_c.roles" :key="index_roles_for" class="container">
                    <input v-if="roles_for.check==true" type="checkbox" class="cus-checkbox" checked="checked" v-on:change="funtionUpdate($event,index_c,index_roles_for)">
                    <input v-else type="checkbox" class="cus-checkbox" v-on:change="funtionUpdate($event,index_c,index_roles_for)">
                    <span class="checkmark"></span>
                    {{roles_for.name}}
                  </label>
                </div>
                <div v-else class="row custom-lable">
                  <div v-for="(sub, index_sub) in item_c.childs" :key="index_sub" class="custom">
                    <div class="row cus-row">
                      <label class="span-label">{{sub.name}}</label>
                      <label v-for="(roles_sub, index_roles_sub) in sub.roles" :key="index_roles_sub" class="container">
                        <input v-if="roles_sub.check==true" type="checkbox" class="cus-checkbox" checked="checked" v-on:change="funtionUpdate($event,index_c,index_roles_sub,index_sub)">
                        <input v-else type="checkbox" class="cus-checkbox" v-on:change="funtionUpdate($event,index_c,index_roles_sub,index_sub)">
                        <span class="checkmark"></span>
                        {{roles_sub.name}}
                      </label>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
    <Toolbar class="fixed-bottom-toolbar">
      <template slot="right">
        <Button
          :label="$constants.TEXT_BUTTON.BACK"
          @click="backToList()"
          class="p-button-warning callback-btn"
        />
        <Button
          v-if="mode == 'edit'"
          :label="$constants.TEXT_BUTTON.EDIT"
          @click="saveData()"
          class="p-button-success main-style-button"
        />
        <Button
          v-else
          :label="$constants.TEXT_BUTTON.ADD"
          @click="saveData()"
          class="p-button-success main-style-button"
        />
      </template>
    </Toolbar>
  </div>
  </form>
</template>

<script>
import DataServices from '@/core/DataServices'
import gql from "graphql-tag";

export default {
  props: {
    mode: String,
    model: Object,
    users: Array,
  },
  data() {
    return {
      name: '',
      checked: '',
      phone: '',
      address: '',
      code: '',
      validationErrors: {},
      formMode: this.mode,
      images: [],
      imagesDeletes: [],
      maxFileSize: 1000000, // 1MB
      maxFiles: 10,
      account_exist: false,
      firebaseID: null,
      userID: null,
      categories: [],
      selectedCategory: null,
      selectedCategory_old : [],
      addCategory: [],
      delCategory: [],

      saving:false,
      addressData:null,
      placeResultData:null,
      /*addressData:{"street_number":"251","route":"Quang Trung","administrative_area_level_1":"Thành phố Hồ Chí Minh","country":"Việt Nam","latitude":10.8286588,"longitude":106.6732573},
      placeResultData:{"address_components":[{"long_name":"251","short_name":"251","types":["street_number"]},{"long_name":"Quang Trung","short_name":"Quang Trung","types":["route"]},{"long_name":"Gò Vấp","short_name":"Gò Vấp","types":["administrative_area_level_2","political"]},{"long_name":"Thành phố Hồ Chí Minh","short_name":"Thành phố Hồ Chí Minh","types":["administrative_area_level_1","political"]},{"long_name":"Việt Nam","short_name":"VN","types":["country","political"]}],"adr_address":"<span class=\"street-address\">251 Quang Trung</span>, <span class=\"extended-address\">Phường 10</span>, <span class=\"locality\">Gò Vấp</span>, <span class=\"region\">Thành phố Hồ Chí Minh</span>, <span class=\"country-name\">Việt Nam</span>","formatted_address":"251 Quang Trung, Phường 10, Gò Vấp, Thành phố Hồ Chí Minh, Việt Nam","geometry":{"location":{"lat":10.8286588,"lng":106.6732573},"viewport":{"south":10.8273459697085,"west":106.6719226697085,"north":10.8300439302915,"east":106.6746206302915}},"icon":"https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png","name":"251 Quang Trung","place_id":"ChIJ8-A91QMpdTERWj0x_fnFL2Y","plus_code":{"compound_code":"RMHF+F8 Gò Vấp, Thành phố Hồ Chí Minh, Việt Nam","global_code":"7P28RMHF+F8"},"reference":"ChIJ8-A91QMpdTERWj0x_fnFL2Y","types":["street_address"],"url":"https://maps.google.com/?q=251+Quang+Trung,+Ph%C6%B0%E1%BB%9Dng+10,+G%C3%B2+V%E1%BA%A5p,+Th%C3%A0nh+ph%E1%BB%91+H%E1%BB%93+Ch%C3%AD+Minh,+Vi%E1%BB%87t+Nam&ftid=0x31752903d53de0f3:0x662fc5f9fd313d5a","utc_offset":420,"html_attributions":[],"utc_offset_minutes":420}*/
      list_controller: [],
      list_role: [],

     selected: [], // Must be an array reference!
      /*options: [
       { text: 'Orange', value: 'orange' },
       { text: 'Apple', value: 'apple' },
       { text: 'Pineapple', value: 'pineapple' },
       { text: 'Grape', value: 'grape' }
     ]*/
    }
  },
  async mounted() {
    var where_controller = {active: {_eq: true},deleted: {_eq: false},is_role: {_eq: true}};
    var order_by_controller = {ordinal_role: 'desc'};
    var controller_list = await  this.$CoreService.getListData('controller',where_controller,null,null,order_by_controller);
    controller_list = this.$commonFuction.convertJsonObject(controller_list);
    console.log("controller_list",controller_list)
    var list_controller_convert = controller_list.length ? controller_list.length:[]
    for ( var s = 0 ; s < list_controller_convert ; s++){
      controller_list[s].name = controller_list[s].name.replace("BC", 'Báo cáo');
    }
    /*if (data){
      this.models = data;
    }*/
    var group_id = this.$route.params.id;
    // group_id =25
    console.log("group_idgroup_idgroup_id:",group_id)
    if (!this.$commonFuction.isEmpty(group_id)){
      var where = {id: {'_eq': group_id}};
      console.log("where",where)
      var dataGroup = await this.$CoreService.getObjectData('group',where);
      console.log("dataGroup:",dataGroup)
      this.name = dataGroup.name;
      var group_roles = (dataGroup.group_roles) ? dataGroup.group_roles:[];
      for (var m = 0; m < controller_list.length; m++) {
        console.log("asajsaskasa",group_roles,"caigidau")
        var elm = controller_list[m];
        // var list_checked_roles = [];
        if(elm.is_parent == 0){
          console.log("asajsaskasa11",controller_list[m].roles)
          if (controller_list[m].roles) {
            console.log("asajsaskasa112")
            var roles = controller_list[m].roles;
            for (var j = 0; j < roles.length; j++) {
              if (group_roles.map((el) => el.role_id).indexOf(roles[j].id) != -1) {
                console.log("asajsaskasa113")
                // list_checked_roles.push(roles[j].id);
                controller_list[m].roles[j].check = true;
              }else{
                controller_list[m].roles[j].check = false;
              }
            }
          }
        }
        else{
          var elme = controller_list[m].childs;
          // console.log("==elme=",elme)
          if (elme) {
            for (let n = 0; n < elme.length; n++) {
              var roles_sub = elme[n].roles
              for (var k = 0; k < roles_sub.length; k++) {
                if (group_roles.map((ell) => ell.role_id).indexOf(roles_sub[k].id) != -1) {
                  controller_list[m].childs[n].roles[k].check = true;
                }else{
                  controller_list[m].childs[n].roles[k].check = false;
                }
              }
            }
          }
        }
        // this.list_controller[m].checked_role = list_checked_roles;
      }
    }
    console.log("==controller_list==",controller_list);
    this.list_controller = controller_list;
    console.log(this.list_controller);

  },
  methods: {
    funtionUpdate(e,ind,ind_role,ind_sub=-1){
      var check = e.target.checked;
      if(ind_sub > -1){
        // console.log("=Trước sub",this.list_controller[ind].childs[ind_sub].roles[ind_role].check)
        this.list_controller[ind].childs[ind_sub].roles[ind_role].check = check;
        // console.log('==Sau sub',this.list_controller[ind].childs[ind_sub].roles[ind_role].check)
      }else{
        // console.log("=Trước",this.list_controller[ind].roles[ind_role].check)
        this.list_controller[ind].roles[ind_role].check = check;
        // console.log('==Sau',this.list_controller[ind].roles[ind_role].check)
      }
    },
    backToList() {
      this.$emit('back');
    },
    async validateData() {
      this.validationErrors = {};

      let name_add = document.getElementById("name");
      console.log("name_add",name_add)
      if (this.name == undefined || this.name == null || this.name == ''){
        name_add.setCustomValidity("Tên không được để trống!");
      }else {
        name_add.setCustomValidity("");
      }

      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()){
        console.log('test=====================');
        inpObj.reportValidity();
        return true;
      }
    },
    convertPhone(numberPhone) {
      var phone = numberPhone
      if (numberPhone[0] === '0') {
        phone = phone.substring(1);
      }
      phone = phone.replace(' ', '')
      phone = '+84' + phone
      return phone
    },
    async getUserByPhoneNumber(phoneNumber) {
      // var phone = phoneNumber;
      // if (phoneNumber[0] === "0") {
      //   phone = phone.substring(1);
      // }
      // phone = phone.replace(' ', '');
      // phone = "+84" + phone;
      let query = `mutation MyMutation {
        checkPhoneNumber(phone: "${this.convertPhone(phoneNumber)}") {
          info
        }
      }`;
      await this.$apollo.mutate({
        mutation: gql(query)
      }).then(async(res) => {
        // console.log('=========> checkPhoneNumber: ', res)
        if (res) {
          this.firebaseID = res.data.checkPhoneNumber.info.uid
          await this.$apollo.query({
            query: DataServices.getList('users', {"fields" : "id"}),
            variables: {
              where_key: {
                phone: {'_eq': this.phone},
                deleted: {'_eq': false}
              }
            },
            fetchPolicy: "network-only"
          }).then((res) => {
            if (res && res.data.users.length > 0) this.userID = res.data.users[0].id
            else this.userID = null
            // console.log(this.userID)
          }).catch((error) => {
            console.log('ERROR: ', error.message)
          })
        }
      }).catch((error) => {
        console.log('ERROR: ', error.message)
        // CHƯA CÓ FIREBASE ID
        this.firebaseID = null
        this.userID = null
      })
    },
    async checkPhoneExist() {
      if (this.phone !== undefined && this.phone !== "") {
        // var phone = this.phone;
        // if (this.phone[0] === "0") {
        //   phone = phone.substring(1);
        // }
        // phone = phone.replace(' ', '');
        // phone = "+84" + phone;

        var where = {
          account: {'_eq': this.phone},
          type: {'_eq': 'PHONE'},
          deleted: {'_eq': false}
        }
        if (this.model !== undefined) where = Object.assign(where, {id: {'_neq': this.model.id}})
        var {data} = await this.$apollo.query({
          query: DataServices.getList('accounts', {"fields" : "id, account, type"}),
          variables: {
            where_key: where
          },
          fetchPolicy: "network-only"
        })
        // console.log(data)
        return data && data[Object.keys(data)].length > 0
      }
    },
    async saveData() {
      console.log("ưqqqqqqqqqqqqq")
     /* if(this.saving == true){
        return;
      }
      this.saving = true;*/
      // this.$commonFuction.is_loading(true);
      if (await this.validateData()) {
       // this.saving = false;
        this.$commonFuction.is_loading(false);
        return
      }
      console.log("ưqqqqqqqqqqqqq")
      if (!this.account_exist) {
        console.log("ưqqqqqqqqqqqqq")
        var params = {
          name: this.name,
          list_controller:this.list_controller
        }
        console.log('saveData', params)
        this.$emit('save', params);
      }
    },
    isImage(file) {
      return /^image\//.test(file.type);
    },
    async onFileSelect(event) {
      let files = event.target.files || event.dataTransfer.files;
      if(files.length > 0) {
        let acceptTags = ['jpeg', 'jpg', 'gif', 'png', 'JPEG', 'JPG', 'PNG', 'GIF']
        for (let i = 0; i < files.length; i++) {
          if (!acceptTags.includes(files[i].name.split('.').pop())) {
            alert("Please upload the image file in the following format: jpeg, jpg, gif, png");
            return;
          }
        }
        if (files.length + this.images.length > this.maxFiles) {
          alert("Hình ảnh quá số lượng tối đa là " + this.maxFiles + " hình");
        } else {
          var arrTemp = [], arrFiles = [];
          for (let file of files) {
            if (this.isImage(file) && file.size <= this.maxFileSize) {
              file.source = file;
              file.objectURL = window.URL.createObjectURL(file);
              arrTemp.push(file);
            }
            if (file.size > this.maxFileSize) arrFiles.push(file);
          }
          
          if (arrFiles.length > 0) {
            alert("Hình ảnh của bạn đã quá dung lượng 1MB");
          } else {
            if (this.images.length > 0 && this.images[0].file === '') {
              this.imagesDeletes.push(this.images[0])
            }
            this.images = [];
            this.images = this.images.concat.apply(this.images, arrTemp);
            // console.log(this.images)
            await this.validateData()
          }
        }
      }
    },
    onFileClear(index) {
      let img = this.images[index];
      this.images.splice(index, 1);
      if (img.file === '') this.imagesDeletes.push(img)
    }
  },
  /*apollo: {
    category: {
      query() {
        return DataServices.getList('category', {"fields" : "id name"});
      },
      update(data) {
        if(data.category){
          this.categories = data.category
        }
      },
      fetchPolicy: "network-only"
    },
  }*/
}
</script>

<style lang="scss">
  .p-multiselect {
    width: 450px;
  }
  .p-multiselect-label:not(.p-placeholder) {
    padding-top: .25rem;
    padding-bottom: .25rem;
    
  }
  .category-item-value {
    padding: .25rem .5rem;
    border-radius: 3px;
    display: inline-flex;
    margin-right: .2rem;
    background-color: var(--primary-color);
    color: var(--primary-color-text);
  }
  .p-multiselect-label-container{
    height: 40px;
  }
  </style>

<style >
.box-permission{
  border: 1px solid #E2E2E2;
  border-radius: 5px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  margin-right: 0px !important;
  margin-left: 0px !important;
  margin-bottom: 16px!important;
}
.box-permission .box-left{
  padding: 24px!important;
  border-right: 1px solid #E2E2E2;
  text-align: left;
}
.box-permission .box-right{
  padding: 24px!important;
}
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #ccc;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #e95d57;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.span-label {
  display: block;
  position: relative;
  font-weight: 600;
}
.custom{
  width: 100%;
}
.cus-row{
  margin-right: 0px;
  margin-left: 0px;
}
.p-offset-1{
  margin: 0px!important;
}
.p-card-field{
  padding: 0px!important;

}
.p-col{
  padding: 0px!important;
}
.box-permission{
  margin-top: 16px!important;
}
.p-fluid .p-inputtext{
  border: 1px solid #E2E2E2!important;
  border-radius: 5px!important;
  padding: 24px!important;
}
.check_box{
  padding: 12px!important;
}
.container input:checked ~ .checkmark{
  background-color:#C40380 !important; ;
  margin-top: 4px!important;
}
label.container{
  margin-left: 8px!important;
}
.permission-left{
  width: 50%!important;
}
.permission-right{
  width: 50%!important;
}

.p-offset-1{
  width: 60%!important;
}

@media screen and (max-width: 600px) {
  .p-offset-1{
    width: 100%!important;
    height: auto!important;
  }
  .box-permission .box-left{
    padding: 10px!important;
  }
  .box-permission .box-right{
    padding: unset!important;
  }

  .permission-left{
    width: 50%!important;
  }
  .permission-right{
    width: 50%!important;
  }
  .div-checkbox{
    padding: 10px!important;
    margin-left: 1px!important;
  }
  .p-col-fixed{
    padding: 0px!important;
  }
  #name{
    padding: 10px!important;
  }
}


@media screen and (max-width: 1000px) {
  .p-offset-1{
    width: 100%!important;
    height: auto!important;
  }
  .box-permission .box-left{
    padding: 10px!important;
  }
  .box-permission .box-right{
    padding: unset!important;
  }

  .permission-left{
    width: 50%!important;
  }
  .permission-right{
    width: 50%!important;
  }
  .div-checkbox{
    padding: 10px!important;
    margin-left: 1px!important;
  }

  .p-col-fixed{
    padding: 0px!important;
  }
  #name{
    padding: 10px!important;
  }

}


</style>
